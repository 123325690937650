<template>
  <CRow>
    <CModal
        :title="
        submitType === 'Update' ? $lang.uid.crud.edit : $lang.uid.crud.create
      "
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        :hide-footer="true"
        color="primary"
    >
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>{{
          err_msg
        }}
      </CAlert>
      <CForm novalidate @submit.prevent="onSubmit">
        <CCardBody>
          <div class="form-group" :class="{ 'form-group--error': $v.uid.countryName.$error }">
            <label class="form__label">{{ $lang.uid.form.countryName }}
              <required_span/>
            </label>
            <v-select :options="CountryModalOptions" v-model="uid.countryName"></v-select>
            <small class="error" v-if="$v.uid.$error && !$v.uid.countryName.required">{{
                $lang.uid.validation.required.countryName
              }}</small>
          </div>
          <div class="form-group" :class="{ 'form-group--error': $v.uid.name.$error }">
            <label class="form__label"
            >{{ $lang.uid.form.name }}
              <required_span/>
            </label>
            <input :placeholder="$lang.uid.form.name" class="form-control" v-model="uid.name"/>
            <small class="error"
                   v-if="$v.uid.name.$error && !$v.uid.name.required">{{ $lang.uid.validation.required.name }}</small>
            <small class="error"
                   v-if="$v.uid.name.$error && !$v.uid.name.minLength">{{ $lang.uid.validation.minLength.name }}
              {{ $v.uid.name.$params.minLength.min }}
              {{ $lang.uid.validation.common.letter }}</small>
          </div>
          <div class="form-group">
            <label class="form__label"
            >{{ $lang.uid.form.regex }}
            </label>
            <input :placeholder="$lang.uid.form.regex" class="form-control" v-model="uid.regex"/>
          </div>
          <div class="form-group">
            <label class="form__label">{{ $lang.uid.form.maxlength }}
            </label>
            <input type="number" :placeholder="$lang.uid.form.maxlength" class="form-control" v-model="uid.maxlength"/>
          </div>

          <!--          <div class="form-group" :class="{ 'form-group&#45;&#45;error': '' }">-->
          <!--            <label class="form__label">{{ $lang.uid.form.isServiceTax }} </label>&nbsp;-->
          <!--            <input-->
          <!--                type="checkbox"-->
          <!--                :placeholder="$lang.uid.form.isServiceTax"-->
          <!--                :value="1"-->
          <!--                @change="changedIsActive"-->
          <!--                v-model="uid.isServiceTax"-->
          <!--            />-->
          <!--          </div>-->

          <div class="form-group" :class="{ 'form-group--error': '' }">
            <label class="form__label">{{ $lang.uid.form.isActive }} </label>&nbsp;
            <input
                type="checkbox"
                :placeholder="$lang.uid.form.isActive"
                :value="1"
                @change="changedIsActive"
                v-model="uid.isActive"
            />
          </div>
        </CCardBody>
        <CCardFooter>
          <CButton
              type="submit"
              size="sm"
              color="primary"
              :disabled="submitted"
          >
            <CIcon name="cil-check-circle"/>
            {{ $lang.buttons.general.crud.submit }}
          </CButton>
        </CCardFooter>
      </CForm>
      <template #footer style="display: none">
        <CButton @click="darkModal = false" color="danger" style="display: none"
        >Discard
        </CButton
        >
        <CButton
            @click="darkModal = false"
            color="success"
            style="display: none"
        >Accept
        </CButton
        >
      </template>
    </CModal>
    <CCol col="12">
      <!--      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>-->
      <!--        {{ err_msg }}-->
      <!--      </CAlert>-->
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.uid.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButton
                    color="primary"
                    v-on:click="createTableRow"
                    v-if="checkPermission('create-state')"
                    style="margin-right: 10px"
                >
                  {{ $lang.buttons.general.crud.create }}
                </CButton>
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="exports(self, 'pdf', module, 'UID')"
                  >
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
                <BeforeFilter
                    :filterShow="toggleFilterValue"
                    @getFilterToggle="switchFilter"
                ></BeforeFilter>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <transition name="fade">
            <CRow v-if="toggleFilterValue">
              <CCol sm="4">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.uid.form.countryName
                    }}</label>
                  <v-select
                      :options="CountryOptions"
                      v-model="uid.countryName"
                  ></v-select>
                </div>
              </CCol>
              <CCol sm="4">
                <div class="form-group" style="margin-top: 30px">
                  <CButton
                      type="submit"
                      size="sm"
                      color="primary"
                      @click="applyFilter()"
                      v-c-tooltip="$lang.common.filter.button.filter"
                  >
                    {{ $lang.common.filter.button.filter }}
                  </CButton>
                  &nbsp;
                  <CButton
                      color="danger"
                      size="sm"
                      @click="resetFilter()"
                      v-c-tooltip="$lang.common.filter.button.cancel"
                  >
                    {{ $lang.common.filter.button.cancel }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </transition>

          <CAlert
              color="success"
              closeButton
              :show.sync="dismissCountDownS"
              fade
          >{{ alertMessage }}
          </CAlert
          >
          <v-server-table
              :columns="columns"
              url="/uids/list"
              :options="options"
              ref="myTable"
              @loaded="exports(self, 'Excel', module, 'UID')"
          >
            <template #isServiceTax="data">
              <CBadge :color="statusBudget(data.row.isServiceTax)">{{ data.row.isServiceTax }}</CBadge>
            </template>
            <template #country="data">
              <span :title="data.row.country.name">{{ trimfunction(data.row.country.name, 20) }}</span>
            </template>
            <template #actions="data">
              <CButton
                  color="success"
                  v-c-tooltip="$lang.buttons.general.crud.edit"
                  v-on:click="editTableRow(data.row.id)"
                  v-if="checkPermission('edit-state')"
              >
                <i class="fas fa-pencil-alt"></i>
              </CButton>
              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.general.crud.delete"
                  v-on:click="deletePopup(data.row.id)"
                  v-if="checkPermission('delete-state')"
              >
                <i class="fas fa-trash"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {UID, country} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import {required, minLength} from "vuelidate/lib/validators";
import VueToasted from "vue-toasted";
import required_span from "../../components/layouts/general/required-span";
import BeforeFilter from "../BeforeFilterOpen";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "UID",
  mixins: [Mixin],
  components: {
    required_span,
    BeforeFilter,
    VueJsonToCsv,
  },
  data() {
    return {
      submitted: false,
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      alertMessage: "test",
      showAlert: false,
      dismissCountDownS: 0,
      msg: "",
      items: [],
      activePage: 1,
      selectedLetter: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      dismissCountDown: 0,
      CountryOptions: [],
      CountryModalOptions: [],
      self: this,
      module: UID,
      countryModule: country,
      uid: {
        name: "",
        regex: "",
        maxlength: "",
        countryName: "",
        isActive: 1,
        isServiceTax: 0,
      },
      columns: ["name", "regex", "country","maxlength", "status"],
      data: [],
      options: {
        headings: {
          country: this.$lang.uid.table.countryName,
          name: this.$lang.uid.table.name,
          regex: this.$lang.uid.table.regex,
          maxlength: this.$lang.uid.table.maxlength,
          //isServiceTax: this.$lang.uid.table.isServiceTax,
          status: this.$lang.uid.table.isActive,
        },
        editableColumns: ["country", "name"],
        sortable: ["country", "name"],
        filterable: ["country", "name"],
      },
    };
  },
  validations: {
    uid: {
      name: {
        required,
        minLength: minLength(1),
      },
      countryName: {
        required,
      },
    },
  },
  created() {
    if (
        this.checkPermission("edit-state") === true ||
        this.checkPermission("delete-state") === true
    ) {
      this.columns.push("actions");
    }
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    // this.loadData();
    axios.get(this.listUrlApi(this.countryModule)).then((response) => {
      response.data.data.map(function (value, key) {
        self.CountryOptions.push({value: value.id, label: value.name});
        self.CountryModalOptions.push({value: value.id, label: value.name});
      });
    });
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    switchFilter(show) {
      this.toggleFilterValue = show;
    },
    changedIsActive(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.uid.isActive = 1;
      } else {
        self.uid.isActive = 0;
      }
    },
    loadData() {
      let self = this;
      axios
          .get(this.listUrlApi(this.module), self.model)
          .then(function (response) {
            if (response.status === 200) {
              self.data = [];
              self.data = response.data.data;
            }
          });
    },
    applyFilter() {
      let self = this;
      const reqObj = {
        countryId: self.uid.countryName.value,
      };
      store.commit("showLoader", true); // Loader Off
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();
    },
    resetFilter() {
      store.commit("showLoader", true); // Loader Off
      this.uid.countryName = {};
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    },
    createTableRow() {
      let self = this;
      self.$v.$reset();
      self.submitType = "Create";
      self.uid.name = "";
      self.uid.regex = "";
      self.uid.maxlength = "";
      self.uid.countryId = "";
      self.largeModal = true;
    },
    editTableRow(item) {
      let self = this;
      self.$v.$reset();
      self.submitType = "Update";
      const id = item;
      self.editId = item;
      axios
          .get(this.viewUrlApi(this.module, id), self.model)
          .then(function (response) {
            if (response) {
              const responseData = response.data.data;
              self.uid.name = responseData.name;
              self.uid.regex = responseData.regex;
              self.uid.isActive = responseData.isActive;
              self.uid.maxlength = responseData.maxlength;
              self.uid.isServiceTax = responseData.isServiceTax;
              self.uid.countryName = {
                value: responseData.country.id,
                label: responseData.country.name,
              };
            } else {
              self.data = [];
            }
          });
      self.largeModal = true;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                const responseData = response.data;
                if (responseData.code === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = responseData.message;
                  self.dismissCountDownS = 10;
                } else {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "danger";
                  self.dismissCountDownS = 10;
                }
              }).catch(function (error) {
            let data;
            if (error.response.data.error) {
              data = error.response.data.error.toString();
            } else {
              data = error.response.data.message;
            }
            self.alertMessage = data;
            self.messageColor = "danger";
            self.dismissCountDownS = 10;
          });
        }
      });
    },
    onSubmit() {
      let self = this;
      this.$v.uid.$touch();
      if (this.$v.uid.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";
        if (this.submitType === "Update") {
          setTimeout(() => {
            let self = this;
            const postData = {
              name: self.uid.name,
              countryId: self.uid.countryName.value,
              status: self.uid.isActive,
              regex: self.uid.regex,
              maxlength: self.uid.maxlength,
              isServiceTax: self.uid.isServiceTax,
            };
            axios
                .post(this.updateUrlweb(this.module, self.editId), postData)
                .then(function (response) {
                  self.submitted = false; //Enable Button
                  if (response.data.code === 200) {
                    self.alertMessage = response.data.message;
                    self.dismissCountDownS = 10;
                    self.largeModal = false;
                    self.$refs.myTable.refresh();
                  } else {
                    self.err_msg = response.data.message;
                    self.dismissCountDown = 10;
                  }
                })
                .catch(function (error) {
                  let data;
                  if (error.response.data.error) {
                    data = error.response.data.error.toString();
                  } else {
                    data = error.response.data.message;
                  }
                  self.err_msg = data;
                  self.dismissCountDown = 10;
                  self.submitted = false; //Enable Button
                });
          }, 500);
        } else {
          setTimeout(() => {
            let self = this;
            const postData = {
              name: self.uid.name,
              regex: self.uid.regex,
              maxlength: self.uid.maxlength,
              countryId: self.uid.countryName.value,
              status: self.uid.isActive,
              isServiceTax: self.uid.isServiceTax,
            };
            axios
                .post(this.createUrlWeb(this.module), postData)
                .then(function (response) {
                  if (response.data.code === 200) {
                    self.alertMessage = response.data.message;
                    self.dismissCountDownS = 10;
                    self.largeModal = false;
                    self.$refs.myTable.refresh();
                    self.submitted = false; //Enable Button
                  } else {
                    self.err_msg = response.data.message;
                    self.dismissCountDown = 10;
                    self.submitted = false; //Enable Button
                  }
                })
                .catch(function (error) {
                  let data;
                  if (error.response.data.error) {
                    data = error.response.data.error.toString();
                  } else {
                    data = error.response.data.message;
                  }
                  self.err_msg = data;
                  self.dismissCountDown = 10;
                  self.submitted = false; //Enable Button
                });
          }, 500);
        }
      }
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
